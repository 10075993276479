import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'


import consulting from '../images/services/new/consulting.jpg'
import virtualization from '../images/services/new/virtualization.jpg'
import cloud from '../images/services/new/cloud.jpg'
import backup from '../images/services/new/backup.jpg'


import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

import infrastructure from '../images/services/new/infrastructure.jpg'
import network from '../images/services/new/pragicts-itops-networking.jpg'
import server from '../images/services/new/pragicts-itops-servers.jpg'
import planning from '../images/services/new/pragicts-itops-capacity-plannig.jpg'
import databackup from '../images/services/new/pragicts-itops-backup-and-recovery.jpg'
import patch from '../images/services/new/pragicts-itops-patch-management.jpg'
import support from '../images/services/new/pragicts-itops-it-support.jpg'
import storage from '../images/services/new/pragicts-itops-storage.jpg'
import asset from '../images/services/new/pragicts-itops-it-asset-management.jpg'


const SolutionsPage2 = () => {
    return (
        <Layout pageTitle="Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2> <span> FEATURES</span></h2>
                   
                                <div className="horizonral-subtitle"><span>SERVICES</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
               
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Features <span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Features</h3> 
                    <h2>COGNIZANT  <span> FEATURES</span></h2>
                    
                </div>
            
                <div className="custom-inner-holder  mar-bottom " >
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                
                                <div className="custom-inner-header ">
                                    
                                    <i className="fal pragicts-feature-16-icon"></i>
                                    <h3>LIVE DASHBOARD</h3>
                                </div>
                              
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Live dashboard for tracking key parameters for effective status tracking and decision making. The dashboard can be customized to meet your exact needs as part of the implementation and configuration process.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-user-icon"></i>
                                    <h3>PERSONAL INFORMATION MANAGEMENT (PIM)</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Personal information management pertaining to employee details e.g. First Name, Last Name, DOB, Nationality, Blood Type, Height, Social Link – facebook, etc.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-education-icon"></i>
                                    <h3>EDUCATION</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Master Data management pertaining to employee education details. Granularity relating to education establishments, qualification categories, grades, etc.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-comp-icon"></i>
                                    <h3>COMPETENCY</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Granularity to record and scope employee competencies and their associated scores.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-health-icon"></i>
                                    <h3>HEALTH AND WELLNESS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Manage key Health and Wellness parameters by date and values. Including doctor and medical reports and its parameters.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-party-icon"></i>
                                    <h3>3RD PARTY APPLICATION INTEGRATION</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Pay architecture enables integration with any 3rd party application.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-database-icon"></i>
                                    <h3>DATA EXTRACTION</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Required data can be extracted to your specific needs and further use and analysis.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-attendance-icon"></i>
                                    <h3>ATTENDANCE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Full management of attendance with integration to bio-metric devices for check-in and check-out time recordings.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-payroll-icon"></i>
                                    <h3>PAYROLL</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Processing of payroll functions.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-feature-10-icon"></i>
                                    <h3>REPORTS</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                    <p>Customized reports to meet your specific needs. These reports can be viewed online and also exported to CSV format for effective analysis and query using Microsoft Excel, etc.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                  

                
                    


                </div>
               

           

                       
            </div>
            <div className="sec-lines"></div>
        </section>

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage2
